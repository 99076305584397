import logo from './logo.svg';
import spacefrog from './spacefrog.png';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={spacefrog} className="App-logo" alt="logo" />
        <p>
          The Frogs Have You Now...
        </p>
      
      </header>
    </div>
  );
}

export default App;
